import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { useState, useEffect } from "react";
import { Box, Modal } from '@mui/material';

const initialOptions = {
    clientId: "AXOglQsiyPeBp1GYe2Ehys3IeS7Od9AngwurkI54YnGXQuWYY9Pyl5aA2GwCpW7Oz1wRRF5JVWxTAh5Y",
    vault: true,
    intent: "subscription",
};

function PayPalPopup(props) {
    const [loadingRedirect, setLoadingRedirect] = useState(false);
    const [hadTrial, setHadTrial] = useState(undefined);

    useEffect(() => {
        const hadTrial = async () => {
            fetch("https://server.nepeto.com/marter_had_trial/" + props.email + "/" + props.affiliate + "/", {
                mode: "cors",
            }).then((response) => response.json())
                .then((data) => {
                    setHadTrial(data)
                    let productId = "106162"
                    if (data) {
                        // Had trial (Need NT)
                        productId = props.yearly === 0 ? "106707" : "106710"
                    } else {
                        productId = props.yearly === 0 ? "106162" : "106709"
                    }
                    window.location.href = `https://store.payproglobal.com/checkout?products%5B1%5D%5Bid%5D=${productId}&page-template=19169&language=en&currency=USD&billing-email=${props.email}`
                })
                .catch(() => {
                    setHadTrial(false);
                    let productId = props.yearly === 0 ? "106162" : "106709"
                    window.location.href = `https://store.payproglobal.com/checkout?products%5B1%5D%5Bid%5D=${productId}&page-template=19169&language=en&currency=USD&billing-email=${props.email}`
                });;
        };
        if (props.email && props.payPalModal) {
            hadTrial();
        }
    }, [props.email, props.payPalModal])

    const buySubscription = async (subscription_id) => {
        setLoadingRedirect(true);
        fetch(
            `https://server.nepeto.com/marter_start/${props.email}/${subscription_id}/${(localStorage.getItem('affiliate') || "none")}/`,
            {
                mode: "cors",
            }
        )
            .then((response) => response.json())
            .then((data) => {
                alert("Thank you for choosing Marter! Go ahead and download the Mobile App and Extension, use your email to log in, and enjoy our software!")
                window.location.reload();
                setLoadingRedirect(false);
            })
            .catch(() => {
                alert("There has been an error. Please contact our support, and they will help you ASAP.");
                setLoadingRedirect(false);
            });
    };

    return (
        <Modal
            open={props.payPalModal}
            onClose={() => props.setPayPalModal(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={{ width: { xs: '100%', sm: 'auto' } }} style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                backgroundColor: 'white',
                border: '2px solid #ffca18',
                borderRadius: "15px",
                boxShadow: 24,
                p: 4,
                display: "grid",
                alighItems: "center",
                justifyContent: "center",
                padding: "3% 2.5% 2.5% 2.5%",
            }}>
                {hadTrial === undefined || true ? <div style={{textAlign: "center"}}><img className="spinningIcon" style={{width: "15%", marginLeft: "-1.9%"}} src="/logo512.png"/><br/>Loading...<br/><strong>Have you seen our 'How to Use' videos yet?</strong></div> :
                    <div style={{ margin: "auto" }}>
                        {<PayPalScriptProvider options={initialOptions}>
                            <PayPalButtons
                                style={{
                                    shape: "rect",
                                    layout: "vertical",
                                }}
                                createSubscription={(data, actions) => {
                                    return actions.subscription.create({
                                        "plan_id": props.yearly === 0 ? (hadTrial ? "P-8H894863EE450953VM4NPJTY" : "P-5XY250081D418791NM2PXLIA") : (hadTrial ? "P-54L21805HX214353BM4NPJ6Q" : "P-4C117956NE783423GM2PXLTA"),
                                        application_context: {
                                            shipping_preference: "NO_SHIPPING"
                                        }
                                    });
                                }}
                                onApprove={(data) => {
                                    buySubscription(data.subscriptionID)
                                }}
                                onCancel={() => ""}
                                onError={() => console.log("Please try again.")} />
                        </PayPalScriptProvider>}

                        {(loadingRedirect) && <div style={{
                            position: 'absolute',
                            zIndex: 2147483647,
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            transform: 'translate3d(0, 0, 0)',
                            backgroundColor: 'rgba(0, 0, 0, 0.8)',
                            background: 'radial-gradient(50% 50%, ellipse closest-corner, rgba(0, 0, 0, 0.6) 1%, rgba(0, 0, 0, 0.8) 100%)',
                            color: '#fff',
                            textAlign: "center",
                            lineHeight: "80vh"
                        }}>Loading...</div>}
                    </div>}
            </Box>
        </Modal>
    );
}

export default PayPalPopup;
