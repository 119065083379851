import { useEffect, useState } from 'react';
import './App.css';
import LandingPage from './LandingPage';
import { Box, Button, Link, Modal, Stack, TextField, Typography } from '@mui/material';
import AffDashboard from './AffDashboard';
import PayPalPopup from './components/PayPalPopup';
import ManageSub from './ManageSub';
import MobileAppPopup from './components/MobileAppPopup';

function App() {
  const [email, setEmail] = useState("");
  const [emailModal, setEmailModal] = useState(false);
  const [resumeSub, setResumeSub] = useState(false);
  const [silentJim, setSilentJim] = useState(false);
  const [yearly, setYearly] = useState(0);
  const [privacy, setPrivacy] = useState(false);
  const [payPalModal, setPayPalModal] = useState(false);
  const [mobileAppPopup, setMobileAppPopup] = useState(false);

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    // Jim Landing Page
    if (urlParams.has("a") && urlParams.get('a') === "silentjim") {
      setSilentJim(true);
    }

    // Check if the 'affiliate' parameter exists in the query string
    if (localStorage.getItem('affiliate') === null) {
      if (urlParams.has("a")) {
        localStorage.setItem("affiliate", urlParams.get('a'))
      } else if (urlParams.has("fbclid")) {
        localStorage.setItem("affiliate", "meta")
      } else if (urlParams.has("gclid")) {
        localStorage.setItem("affiliate", "google")
      }
    }
    if (urlParams.has("canceledPromotion")) {
      alert("Use the coupon code '10OFF' to get 10% OFF!")
      window.location.href = "https://www.getmarter.com/"
    }
    if (urlParams.has("resumeSub")) {
      setResumeSub(true);
      setEmailModal(true);
    }
    if (window.location.pathname === "/privacy") {
      setPrivacy(true)
    }
    if (window.location.pathname === "/webinar") {
      window.location.href = "https://us22.list-manage.com/survey?u=1674faa86d4f825975148f4ea&id=255b024084&attribution=false"
    }
  }, []);

  const signUp = async (e) => {
    if (email === "") {
      alert("Please enter your email address to the left of the 'Start Free Trial' button.")
    } else {
      setPayPalModal(true);
    }
  };

  if (window.location.href.includes("affiliate-dashboard")) {
    return <div className="App" style={{
      width: '100%',
      backgroundImage: 'linear-gradient(180deg, rgba(255,202,24,0.25), #FFF)',
      backgroundSize: '100% 20%',
      backgroundRepeat: 'no-repeat',
      height: "100vh",
      marginTop: "-5vh"
    }}>
      <AffDashboard />
    </div>
  }

  if (window.location.href.includes("manage-subscription")) {
    return <div className="App" style={{
      width: '100%',
      backgroundImage: 'linear-gradient(180deg, rgba(255,202,24,0.25), #FFF)',
      backgroundSize: '100% 20%',
      backgroundRepeat: 'no-repeat',
      height: "100vh",
      marginTop: "-5vh"
    }}>
      <ManageSub />
    </div>
  }

  return (
    <div className="App">
      {privacy && <pre style={{whiteSpace: "pre-line"}}><br/><br/><br/><br/><br/>
      Privacy Terms<br/>
          Effective Date: October 26, 2023<br/>
          Introduction<br/>
          These Privacy Terms ("Provisions") govern the acquisition, utilization, and dissemination of<br/>
          personal data when you interact with or utilize the services provided by Marter ("we," "us,"<br/>
          or "our") through our website, application, or affiliated offerings, collectively referred to as<br/>
          the "Services."<br/>
          By engaging with or utilizing our Services, you grant your consent to the procedures<br/>
          delineated in these Provisions and agree to the gathering, utilization, and disclosure of your<br/>
          personal data as expounded below. If you dissent with these Provisions, kindly abstain from<br/>
          using our Services.<br/>
          Information We Gather<br/>
          We may amass the ensuing personal data from you, either voluntarily furnished or through<br/>
          your interaction with our Services:<br/>
          1. Email Address: The collection of your email address is intended for disseminating<br/>
          crucial updates, notifications, and promotional material germane to our Services.<br/>
          2. Credit Card Data: Your credit card data is collected and securely stored via a<br/>
          reputable third-party payment processor like Stripe to streamline your purchases<br/>
          and facilitate payment processing for the products you request.<br/>
          <strong>3. Google Sheets Access: To enable the export of data to Google Sheets, we require<br/>
          access to your Google Sheets. This allows us to either create new Google Sheets<br/>
          or append data to existing ones. We will retrieve all your existing Google Sheets<br/>
          to let you choose where to append data in this case.</strong><br/>
          Utilization of Personal Data<br/>
          We leverage the personal data amassed for the ensuing objectives:<br/>
          • Service Provision: Your hidden products, telephone number, email address, and<br/>
          product particulars are employed to grant you access to our Services and to bestow<br/>
          functionality, including account management, order processing, and client support.<br/>
          • Communication: Your telephone number and email address may be employed to<br/>
          initiate communication concerning your account and responses to your inquiries.<br/>
          Data Security<br/>
          We execute judicious security measures to safeguard your personal data from unauthorized<br/>
          access, revelation, modification, or obliteration. Nevertheless, no mode of internet<br/>
          transmission or electronic storage can warrant unalloyed security, and we are unable to<br/>
          vouchsafe complete security.<br/>
          Retention of Personal Data<br/>
          We maintain your personal data for the duration essential to fulfill the objectives delineated<br/>
          in these Provisions, except in instances where the law requires or permits an extended<br/>
          retention period.<br/>
          Disclosure of Personal Data<br/>
          We may reveal your personal data to third parties under the ensuing situations:<br/>
          • Service Providers: We may enlist reputable third-party service providers to aid in<br/>
          the provision or enhancement of our Services, including payment processors. These<br/>
          providers will gain access to your personal data but are bound by a commitment to<br/>
          preserve its confidentiality and employ it solely for purposes defined by us.<br/>
          • Legal Compliance: We may divulge your personal data when compelled by law or in<br/>
          response to valid legal requests, such as court orders or subpoenas.<br/>
          Your Options and Entitlements<br/>
          You possess the ensuing options and entitlements regarding your personal data:<br/>
          • Access and Revision: You may access and revise your personal data by contacting us<br/>
          via the details furnished below.<br/>
          • Erasure: You may request the erasure of your personal data by reaching out to us<br/>
          using the details provided below. Nevertheless, please be aware that specific data<br/>
          may need to be retained for legal or legitimate business motives.<br/>
          Contacting Us<br/>
          If you have queries, reservations, or grievances regarding our privacy practices or wish to<br/>
          avail yourself of your entitlements, kindly reach out to us at: info@getmarter.com<br/>
          Modifications to these Provisions<br/>
          We may periodically revise these Privacy Provisions. The most current iteration of the<br/>
          Privacy Provisions will be published on our website. We encourage you to review these<br/>
          Provisions at intervals.<br/>
          By continuing to utilize our Services after the effective date of any modifications, you<br/>
          embrace and concur with the revised Privacy Provisions.<br/>
          We appreciate your diligence in reading and comprehending our privacy provisions.<br/>
          Marter (getmarter.com).<br/><br/><br/><br/><br/>
          </pre>}
      <LandingPage setMobileAppPopup={setMobileAppPopup} signUp={signUp} setEmail={setEmail} setEmailModal={setEmailModal} setYearly={setYearly} silentJim={silentJim} />
      <Modal
        open={emailModal}
        onClose={() => setEmailModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ width: { xs: '100%', sm: 'auto' } }} style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: 'white',
          border: '2px solid #ffca18',
          borderRadius: "15px",
          boxShadow: 24,
          p: 4,
          display: "grid",
          alighItems: "center",
          justifyContent: "center"
        }}>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            alignSelf="center"
            spacing={1}
            useFlexGap
            sx={{ pt: 2, width: { xs: '100%', sm: 'auto' }, p: "20px" }}
            style={{ margin: "auto" }}
          >
            <TextField
              id="outlined-basic"
              hiddenLabel
              size="small"
              variant="outlined"
              aria-label="Enter your email address"
              placeholder="Your email address"
              name="email"
              inputProps={{
                autocomplete: 'off',
                ariaLabel: 'Enter your email address',
              }}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Button variant="contained" style={{ backgroundColor: "rgb(230,175,0)" }} onClick={(e) => signUp(e)}>
              {resumeSub ? "Resume Subscription" : "Start your Free Trial"}
            </Button>
          </Stack>
          <Typography variant="caption" textAlign="center" sx={{ opacity: 0.8 }}>
            By clicking &quot;Start&quot; you agree to our&nbsp;
            <Link href="/terms.pdf" color="primary">
              Terms & Conditions
            </Link>
            .
          </Typography>
        </Box>
      </Modal>

      <PayPalPopup affiliate={localStorage.getItem('affiliate')} payPalModal={payPalModal} setPayPalModal={setPayPalModal} yearly={yearly} email={email}/>
      <MobileAppPopup mobileAppPopup={mobileAppPopup} setMobileAppPopup={setMobileAppPopup} />
    </div>
  );
}

export default App;
