import { useState, useEffect } from "react";
import { Box, Modal } from '@mui/material';

function MobileAppPopup(props) {
    return (
        <Modal
            open={props.mobileAppPopup}
            onClose={() => props.setMobileAppPopup(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={{ width: { xs: '75%', sm: 'auto' } }} style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                backgroundColor: 'white',
                border: '2px solid #ffca18',
                borderRadius: "15px",
                boxShadow: 24,
                p: 5,
                display: "grid",
                alighItems: "center",
                justifyContent: "center",
                padding: "3% 2.5% 2.5% 2.5%",
            }}>
                <div style={{ margin: "auto", textAlign: "center" }}>
                    <strong>Download our RA Mobile app today!</strong>
                    <br /><br />
                    <img src="/appStoreS.png" style={{ cursor: "pointer" }} onClick={() => window.open("https://apps.apple.com/us/app/marter/id6630382798")} /><br /><br />
                    <img src="/googlePlayS.png" style={{ cursor: "pointer" }} onClick={() => window.open("https://marter.app")} />
                </div>
            </Box>
        </Modal>
    );
}

export default MobileAppPopup;
